import React from 'react'
import { Bar } from 'react-chartjs-2'
import 'chartjs-plugin-deferred'

class Chart extends React.Component {
  render() {
    const {data, title, options} = this.props

    return (
      <div className='o-chart'>
        <h3 className='o-chart__title'>{title}</h3>
        <Bar data={data} options={options} />
      </div>
    );
  }
}

export default Chart