import React from 'react'
import Divider from 'components/divider'
import Chart from 'components/chart'
import { chartDeferredOptions } from 'chart-options'

const data = {
  labels: [
    '2020', '2021', '2022', '2023', '2024'
  ],
  datasets: [
    {
      label: 'Dividend',
      data: [3,3,3,3,6],
      backgroundColor: 'rgba(220, 100, 60, 0.5)',
      borderColor: '#dc643c',
      borderSkipped: false,
      borderWidth: 1,
    },
    {
      label: 'Buy back of shares',
      data: [0,0,0,4,6],
      backgroundColor: 'rgba(5, 20, 50, 0.3)',
      borderColor: '#051432',
      borderSkipped: false,
      borderWidth: 1,
    },

  ],
}

const options = Object.assign({
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    labels: {
      fontSize: 14,
      fontFamily: 'Helvetica Neue',
      fontColor: '#525252',
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: { fontSize: 14 },
        categoryPercentage: 0.6,
        barPercentage: 0.75,
        stacked: true,
      }
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          fontSize: 14,
          min: 0,
          max: 12,
          callback: function (label) {
            return label.toFixed(0) + ''
          }
        },
      }
    ]
  }
}, chartDeferredOptions)

export default () => (
  <section className="padding-md">
    <Divider color="terracotta" />
    <h2 className="mt-20 h2">
      Return of Capital
    </h2>
    <Chart
      title="(pence per share)"
      data={data}
      options={options}
    />
  </section>
)