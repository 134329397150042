import React from "react"
import { Helmet } from "react-helmet"
import Layout from "layouts/layout"
import ColumnLayout from "layouts/column-layout"

// Page sections
import KeyDocuments from "./_key-documents"
import Intro from "./_intro"
import TopTenHoldingsTable from "./_tables/_top-ten-holdings"
import PortraitQuote from "components/portrait-quote"
import AssetValue from "./_charts/_asset_value"
import Dividend from "./_charts/_dividend"
import Capacity from "./_capacity"

import CTA from "components/call-to-action"

class Overview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showHistoricalFigures: false,
      historicalFiguresHeight: 0,
    }

    this.toggleHistoricalFigures = this.toggleHistoricalFigures.bind(this)
  }

  toggleHistoricalFigures() {
    const { historicalFiguresHeight, showHistoricalFigures } = this.state
    this.setState({
      historicalFiguresHeight: historicalFiguresHeight === 0 ? "auto" : 0,
      showHistoricalFigures: !showHistoricalFigures,
    })
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Portfolio and capacity | Helios Underwriting</title>
        </Helmet>
        <ColumnLayout aside={<KeyDocuments />}>
          <Intro />

          <PortraitQuote
            name="Michael Wade"
            title="Executive Chariman"
            image="/assets/quote-portrait-800-michael.png"
          >
            A shareholding in Helios enables an investor the unique opportunity
            of a stock exchange listed vehicle which has direct participation
            across a spread of Lloyd’s syndicates.
          </PortraitQuote>

          <Capacity />

          <AssetValue />

          <TopTenHoldingsTable />

          <Dividend />

          {/* <InheritanceTax /> */}

          <CTA
            title="Directors and management"
            link="/about-helios-underwriting/directors-and-management"
          />
        </ColumnLayout>
      </Layout>
    )
  }
}

export default Overview
