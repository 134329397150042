import React from 'react'

export default () => (
  <section className="padding-md">
    <h1 className="h1 mb-40">
      Building a high quality portfolio at Lloyd’s
    </h1>

    <div className="u-color-emperor mb-20">
      <p className="lead mb-20">
      The underwriting results of the Helios portfolio have consistently outperformed the Lloyd’s market average, with the average return being 9% higher than the Lloyd’s average (last five years).
      </p>
      <p>
        These incremental returns demonstrate the diversity and the breadth of underwriting expertise within the businesses comprising the portfolio of syndicate capacity.
      </p>
    </div>

  </section>
)